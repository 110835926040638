import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import options from "../contentfulOptions"
import Layout from "../components/layout"
import SEO from "../components/seo"

class PostTemplate extends Component {
  render() {
    const post = this.props.data.contentfulPost
    return (
      <Layout>
        <SEO
          title={post.title}
          description={post.description}
          keywords={post.tags}
        />
        <Link className="back" to="/">
          « back to overview
        </Link>
        <h1>{post.title}</h1>
        <section>
          {documentToReactComponents(post.content.json, options)}
        </section>
        <Link
          className="amp-comments"
          to={`${this.props.location.pathname.slice(0, -4)}#comments`}
        >
          Go to comments
        </Link>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    contentfulPost(id: { eq: $id }) {
      id
      slug
      title
      tags
      description
      content {
        json
      }
    }
  }
`

export default PostTemplate
